<template>
  <b-card-code
    title=""
  >
    <b-card-body>
      <b-row>
        <b-col cols="6">
          <div class="custom-search d-flex justify-content-start">
            <b-button
              v-if="accessPermissions('category.store')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              :to="{ name: 'settings-categories-create' }"
            >
              <feather-icon
                icon="PlusCircleIcon"
                class="mr-50"
              />
              <span class="align-middle">Nuevo</span>
            </b-button>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <b-form-input
                v-if="accessPermissions('category.index')"
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Buscar..."
                class="d-inline-block"
              />
            </b-form-group>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-table
            v-if="accessPermissions('category.list.index')"
            striped
            hover
            responsive
            class="position-relative"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="fields"
            :items="rows"
            :filter="filter"
            @filtered="onFiltered"
          >
            <template #cell(color)="data">
              <div
                class="badge badge-default text-white"
                :style="{backgroundColor: `${data.item.color} !important`}"
              >
                {{ data.item.color }}
              </div>
            </template>

            <template #cell(action)="data">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template
                  v-if="accessPermissions('category.update') || accessPermissions('category.destroy')"
                  v-slot:button-content
                >
                  <feather-icon
                    icon="SettingsIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-if="accessPermissions('category.update')"
                  :to="{ name: 'settings-categories-edit', params: { id: data.item.id } }"
                >
                  <feather-icon
                    icon="EyeIcon"
                    class="mr-50"
                  />
                  <span>Editar</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="accessPermissions('category.destroy')"
                  @click="onHandleDelete(data.item.id)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Eliminar</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <div class="custom-search d-flex justify-content-start">
            <b-form-group
              label="Por Página"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="custom-search d-flex justify-content-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card-code>
</template>

<script>
import {
  BCardBody,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BPagination,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BTable,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import CategoriesServices from './services/CategoriesServices'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'
import { getItem, setItem } from '@/libs/storage'

export default {
  name: 'CategoryPage',
  components: {
    BCardBody,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BPagination,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BTable,
    BCardCode,
  },
  data() {
    return {
      perPage: 50,
      pageOptions: [10, 20, 50, 100],
      totalRows: 0,
      currentPage: 1,
      filter: null,
      fields: [
        {
          key: 'name', label: 'Nombre y Apellido',
        },
        {
          key: 'color', label: 'Color',
        },
        {
          key: 'action', label: 'Acción',
        },
      ],
      rows: [],
    }
  },
  watch: {
    filter(value) {
      setItem('search_settings_categories', value)
    },
  },
  mounted() {
    this.filter = getItem('search_settings_categories') === undefined ? '' : getItem('search_settings_categories')
  },
  async created() {
    await this.onHandleList()
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async onHandleDelete(data) {
      if (this.accessPermissions('category.destroy')) {
        await this.$swal({
          title: '¿Está seguro que desea eliminar la categoría?',
          icon: 'warning',
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Si',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          showClass: {
            popup: 'animate__animated animate__bounceIn',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            CategoriesServices.handleDelete(data).then(response => {
              if (response.data.data) {
                this.onHandleList()
              }
            }).catch(error => this.errorResp(error))
          }
        })
      }
    },
    async onHandleList() {
      if (this.accessPermissions('category.index')) {
        await CategoriesServices.handleList().then(response => {
          this.rows = response.data.data
          this.totalRows = response.data.data.length
        }).catch(error => this.errorResp(error))
      }
    },
  },
}
</script>
